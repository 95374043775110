import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import R from 'ramda'

function SEO({ description, title, image }) {
  const {
    metaTitle: siteMetaTitle,
    metaDescription: siteMetaDescription,
    author,
    socialMediaImage: siteImage,
  } = siteMetaQuery()

  const metaDescription = description || siteMetaDescription
  const metaImage = image || (siteImage[0] ? siteImage[0].thumbnail : '')
  const socialTitle = title || siteMetaTitle

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={title}
      titleTemplate={`%s | ${siteMetaTitle}`}
      defaultTitle={siteMetaTitle}
    >
      <meta name="description" content={metaDescription} />

      <meta property="og:title" content={socialTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:title" content={socialTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />
    </Helmet>
  )
}

SEO.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
}

const siteMetaQuery = R.pipe(
  () =>
    useStaticQuery(
      graphql`
        query {
          craft {
            globals {
              siteMeta {
                metaTitle
                metaDescription
                author
                socialMediaImage {
                  thumbnail: url(transform: metaImage)
                }
              }
            }
          }
        }
      `
    ),
  R.path(['craft', 'globals', 'siteMeta'])
)

export default SEO
